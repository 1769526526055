import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import BlogItem from "./BlogItem"
import { initializePaddle, Paddle } from '@paddle/paddle-js';

const Blog = () => {
    const [paddle, setPaddle] = useState();
    // let Paddle = {};

    initializePaddle({ environment: 'production', token: 'live_c6b642938c2d4045b9f6203314d' }).then(
        (paddleInstance) => {
            if (paddleInstance) {
                setPaddle(paddleInstance);
            }
        },
    );

    const openCheckout = (id) => {
        console.log('openCheckout', id);
        paddle?.Checkout.open({
            items: [{ priceId: id, quantity: 1 }],
        });
    }

    const articles = useStaticQuery(graphql`
    {
        allMarkdownRemark {
        nodes {
            frontmatter {
                title
                id
                language
                cats
                cover
                description
                preview
                slug
                author,
                countdown
            }
            html
            }
        }
    }
    `)

    const allArticles = articles.allMarkdownRemark.nodes.map((item, index) => (
        <BlogItem
        key={index}
        id={item.frontmatter.id}
        slug={item.frontmatter.slug}
        alt={item.frontmatter.title}
        title={item.frontmatter.title}
        description={item.frontmatter.description}
        preview={item.frontmatter.preview}
        cover={item.frontmatter.cover}
        countdown={item.frontmatter.countdown}
        />
    ))
    return (
        <>
            <div className="mx-auto max-w-7xl px-4 sm:px-6">
                <div className="w-full pb-10">
                    <main className="mb-16">
                        <h2 className="text-gray-800 font-bold text-xl lg:text-4xl leading-none mb-2">Unsere SAP Videos und Tutorials</h2>
                        <p className="mb-8 text-gray-700 font-medium">Weitere Lerninhalte wie Bücher, Lernpfade und Zertifikate finden Sie auf unserer Lernplattform <a className="text-blue-500 hover:text-orange-500 duration-200 ease-in-out transition-colors" href="https://et.training" target="_blank" rel="noopener" title="SAP-Lernplattform et.training öffnen">et.training</a>.</p>
                        <div className="grid grid-cols-1 xs:grid-cols-2 lg:grid-cols-4 gap-4 md:gap-8 lg:gap-16">
                            {allArticles}
                        </div>
                    </main>
                </div>
            </div>
            <div className="mx-auto max-w-3xl px-4 sm:px-6 xl:max-w-5xl xl:px-0 flex flex-row flex-wrap py-10">
                <div className="w-full bg-blue-700 rounded-lg shadow-center py-4 md:py-8 lg:py-12 ng-star-inserted">
                    <div className="w-full flex justify-center">
                        <div className="w-full sm:w-4/5">
                            <p className="text-white font-black text-4xl text-center leading-tight">Alle Inhalte. Mehr Informationen. Jetzt entdecken.</p>
                            <p className="text-white font-bold mt-4 text-xl text-center leading-tight"><a className="text-orange-500 hover:underline" href="https://et.training">et.training</a> - Ihre Lernplattform für SAP-Software</p>
                        </div>
                    </div>
                    <div  className="w-full flex justify-center">
                        <div  className="w-full sm:w-3/4 md:w-3/5">
                            <ul  className="text-white mt-8">
                                <li className="flex flex-row justify-start text-lg mb-2">
                                    <i className="mr-2 text-orange-500">
                                        <svg className="block h-6 w-4" role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path>
                                        </svg>
                                    </i>
                                    <span>Zugriff auf alle Lerninhalte<sup>1</sup></span>
                                </li>
                                <li className="flex flex-row justify-start text-lg mb-2">
                                    <i className="mr-2 text-orange-500">
                                        <svg className="block h-6 w-4" role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path>
                                        </svg>
                                    </i>
                                    <span >Regelmäßige Neuerscheinungen</span>
                                </li>
                                <li className="flex flex-row justify-start text-lg mb-2">
                                    <i className="mr-2 text-orange-500">
                                        <svg className="block h-6 w-4" role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path>
                                        </svg>
                                    </i>
                                    <span >Intelligenter Suchalgorithmus</span>
                                </li>
                                <li className="flex flex-row justify-start text-lg mb-2">
                                    <i className="mr-2 text-orange-500">
                                        <svg className="block h-6 w-4" role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path>
                                        </svg>
                                    </i>
                                    <span>Innovatives Leseerlebnis</span>
                                </li>
                                <li className="flex flex-row justify-start text-lg mb-2">
                                    <i className="mr-2 text-orange-500">
                                        <svg className="block h-6 w-4" role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path>
                                        </svg>
                                    </i>
                                    <span >Maßgeschneiderte Lernpfade</span>
                                </li>
                                <li  className="flex flex-row justify-start text-lg">
                                    <i className="mr-2 text-orange-500">
                                        <svg className="block h-6 w-4" role="img" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path fill="currentColor" d="M470.6 105.4c12.5 12.5 12.5 32.8 0 45.3l-256 256c-12.5 12.5-32.8 12.5-45.3 0l-128-128c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L192 338.7 425.4 105.4c12.5-12.5 32.8-12.5 45.3 0z"></path>
                                        </svg>
                                    </i>
                                    <span >Zertifikate &amp; QA-Tests<sup>2</sup></span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div  className="w-full bg-blue-600 p-4 mt-8">
                        <button onClick={() => openCheckout('pri_01hmrm6kp3eetne4yby8pnmcgn')} className="appearance-none cursor-pointer focus:outline-none w-full bg-blue-500 hover:bg-orange-500 duration-200 ease-in-out transition-colors p-4 mb-4 flex flex-row flex-wrap rounded-md" title="Jetzt bestellen">
                            <div className="w-full sm:w-1/2 text-left">
                                <p className="text-white text-lg md:text-xl font-bold">Schnuppertarif</p>
                                <p className="text-white text-sm font-medium">
                                    1 Monat <span className="text-gray-600 font-normal">(Verlängert sich automatisch)</span>
                                </p>
                            </div>
                            <div className="w-full sm:w-1/2 flex flex-wrap justify-center sm:justify-end">
                                <p className="w-full items-baseline text-white text-3xl font-extrabold tracking-tight leading-none text-center sm:text-right">
                                    39 €
                                </p>
                                <p className="text-sm text-gray-600 font-normal text-center sm:text-right">
                                    <strong>468 € pro Jahr</strong>
                                </p>
                            </div>
                        </button>
                        <button onClick={() => openCheckout('pri_01hmrm59sfy4d7e5sed30b1jjy')} className="appearance-none cursor-pointer focus:outline-none w-full bg-blue-500 hover:bg-orange-500 duration-200 ease-in-out transition-colors p-4 mb-4 flex flex-row flex-wrap rounded-md" title="Jetzt bestellen">
                            <div className="w-full sm:w-1/2 text-left">
                                <p className="text-white text-lg md:text-xl font-bold">
                                    365 Tage im Jahr lernen
                                </p>
                                <p className="text-white text-sm font-medium">
                                    12 Monate <span  className="text-gray-600 font-normal">(Verlängert sich automatisch)</span>
                                </p>
                            </div>
                            <div className="w-full sm:w-1/2 flex flex-wrap justify-center sm:justify-end">
                                <p className="w-full items-baseline text-white text-3xl font-extrabold tracking-tight leading-none text-center sm:text-right">
                                    228 €
                                </p>
                                <p className="text-sm text-gray-600 font-normal text-center sm:text-right">
                                    <strong>19 € pro Monat</strong>
                                </p>
                            </div>
                        </button>
                    </div>
                    <p className="text-center mt-8 leading-none">
                        <a className="text-white font-bold hover:text-orange-500 duration-200 ease-in-out transition-colors" href="https://et.training/login" target="_blank" rel="noopener nofollow">Sie haben bereits ein Konto?</a>
                    </p>
                </div>
                <div className="text-white text-xs mt-8 leading-none ng-star-inserted">
                    <p className="text-xs text-gray-650">
                        <sup>1</sup> Sie erhalten Zugriff auf alle Lerninhalte. Online-Trainings, Zertifikate sind NICHT Teil der Flatrate.
                    </p>
                    <p className="text-xs text-gray-650">
                        <sup>2</sup> Weitere Informationen auf Anfrage.
                    </p>
                </div>
            </div>
        </>
    )
}

export default Blog
